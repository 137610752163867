.pagination-container
  display: flex
  align-items: center
  justify-content: space-between
  margin: 24px 16px
  .pagination
    list-style: none
    display: flex
    padding: 0
    align-items: center
    margin: 0
    .next, .previous
      width: 40px
      height: 40px
      display: flex
      justify-content: center
      align-items: center
      position: relative
      svg
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        *
          stroke: var(--main)
          transition: .1s
      &.disabled svg *
        stroke: var(--mainLighter)
    .next
      svg
        transform: translate(-50%, -50%) rotate(180deg)
    li
      width: 34px
      height: 34px
      & + li
        margin-left: 8px
      &.selected
        a
          background: var(--mainTransparent)
          color: var(--main)
      a
        display: block
        width: 100%
        height: 100%
        border-radius: 2px
        line-height: 24px
        font-size: 16px
        padding: 4px 0
        text-align: center
        cursor: pointer
        transition: .15s
        user-select: none
        &:hover
          background: var(--mainTransparent)
          color: var(--main)
  .pagination-per-page
    div
      display: inline-block
    span:not(.per-page)
      font-size: 13px
      color: var(--textColor)
      margin-right: 16px
    span.per-page
      padding: 4px 8px
      border: 2px solid var(--textColorLight)
      font-size: 16px
      line-height: 24px
      cursor: pointer
      transition: .1s
      &:first-child
        border-radius: var(--borderRadius) 0 0 var(--borderRadius)
        padding-left: 10px
      &:last-child
        border-radius: 0 var(--borderRadius) var(--borderRadius) 0 
        padding-right: 10px
      &[data-selected=true]
        border-color: var(--mainLight)
        color: var(--main)
        font-weight: 600
      &:hover
        border-color: var(--mainLight)