
.form-response {
  padding: 12px;
  position: relative;
  transition: .15s;
}
.form-response::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5;
}

.form-response.error::before {
  background-color: var(--danger);
}
.form-response.success::before {
  background-color: var(--main);
}
.form-response.info::before {
  background-color: var(--secondary);
}

.form-response svg {
  height: 1.375rem;
  width: auto;
  margin-bottom: -5px;
  margin-right: 10px;
}

.form-response.hidden {
  visibility: hidden;
  opacity: 0;
}