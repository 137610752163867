

.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--overlayBackgroundColor);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
}

.modal {
  background-color: var(--backgroundColor);
  border-radius: var(--borderRadius);
  box-shadow: var(--shadow);
  min-width: 400px;
  max-width: 1200px;
  overflow: hidden;
}

.modal .modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  box-shadow: var(--shadow);
}

.modal .modal-title p {
  margin: 0;
  font-size: 1.3rem;
}

.modal .modal-body {
  padding: 32px;
}

.modal-overlay {
  transition: .2s;
  opacity: 0;
}
.modal-overlay-after-open {
  opacity: 1;
}
.modal-overlay-before-close {
  opacity: 0;
  pointer-events: none;
}

.modal:focus-visible {
  outline: none;
}