.center-wrap {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-wrap form {
  width: 280px;
}

.center-wrap form h1 {
  text-align: center;
}