
.button.view-cards
  font-size: 13px
  padding: 4px
  max-width: 70px
  margin: 0

.submission-modal
  display: flex
  flex-direction: column
  max-height: 80vh
  overflow-y: scroll
  padding: 24px
  margin: -32px
  &.no-data
    opacity: 0
  section
    ul
      padding: 0
      margin: 0
      display: flex
      flex-wrap: wrap
      list-style: none
      align-items: center
      li.attribute-item
        width: 120px
        height: 120px
        font-size: 14px
        text-align: center
        margin-top: 0
        margin-right: 12px
        margin-bottom: 12px
        display: flex
        flex-direction: column
        justify-content: space-around
        align-items: center
        img
          width: 50%