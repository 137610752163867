

.dummy-data-form
  padding-top: 20px
  button[type=submit]
    width: 200px
    margin: 0 auto
    display: block
  .fields
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    .labeled-text-input
      width: 45%
  .assessments-field
    margin-bottom: 40px
    .assessment
      display: flex
      padding-left: 16px
      margin-bottom: 60px
      &:hover
        .aside
          .remove
            opacity: 1
      .aside
        font-size: 24px
        font-weight: bold
        margin-right: 16px
        padding-right: 16px
        border-right: 1px solid var(--textColorLight)
        color: var(--textColorLight)
        opacity: 0.5
        display: flex
        flex-direction: column
        align-items: center
        .remove
          font-size: 18px
          border-radius: 50%
          width: 32px
          height: 32px
          display: flex
          align-items: center
          justify-content: center
          transition: .10s
          opacity: 0
          margin-top: 12px
          cursor: pointer
          &:hover
            box-shadow: var(--shadow)
      .fields
        display: flex
        width: 100%
        flex-wrap: wrap
        justify-content: space-between
        & > *
          width: 48%
        & > *:last-child
          margin-bottom: 0
    .add-assessment
      width: 30%
      margin: 0 auto
      margin-top: 40px
      text-align: center
      cursor: pointer
      padding: 12px
      transition: .10s
      svg
        fill: var(--textColorLight)
        transition: .10s
      &:hover
        // box-shadow: 0 0 12px var(--main)
        width: 35%
        svg
          fill: var(--main)

.dummy-data-success
  display: flex
  flex-direction: column
  align-items: center
  text-align: center
  justify-content: center
  height: 80vh
  h1
    color: var(--main)
  p
    max-width: 600px
    span.m
      color: var(--main)
    span.s
      color: var(--secondary)
  button
    max-width: 200px