.navigation {
  position: fixed;
  height: 100vh;
  width: 300px;
  top: 0;
  left: 0;
  padding-top: 40px;
  box-shadow: var(--shadow);
}

.navigation nav {
  display: flex;
  flex-direction: column;
  padding: 16px;
}