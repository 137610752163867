.icon-button {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  position: relative;
  cursor: pointer;
}

.icon-button::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--textColor);
  opacity: 0.1;
  transition: .15s;
}

.icon-button:hover::before {
  opacity: 1;
}

.icon-button:hover svg {
  fill: var(--main);
}

.icon-button svg {
  font-size: 18px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  transition: .15s;
}