
table.table-list {
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
  overflow-y: hidden;
  height: 128px;
}

table.table-list td, 
table.table-list th {
  padding: 24px 16px;
  text-align: start;
}

table.table-list tr {
  transition: .15s;
}

table.table-list tbody tr:hover {
  background: var(--shadowColor);
}

table.table-list td.controls-container {
  width: 48px;
  padding: 0;
}

table.table-list td .controls {
  width: 64px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* table.table-list td.transformed {
  padding: 0;
} */

table.table-list td .hoverable {
  transition: .15s;
}

table.table-list td .hoverable:hover {
  background-color: var(--secondary);
  color: #fff;
}

.table-list-query {
  border-radius: var(--borderRadius);
  box-shadow: var(--shadow);
  padding: 20px 0 10px;
}