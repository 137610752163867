.checkbox
  height: 32px
  label
    cursor: pointer
  &-view
    width: 16px
    height: 16px
    background: #fdfdfd
    border: 1px solid #bcbcbc
    border-radius: 2px
    display: inline-flex
    margin-right: 12px
    margin-top: 12px
    position: relative
    transition: .1s
    align-items: center
    justify-content: center
    svg
      opacity: 0
      transition: .1s
      fill: #fff
  input:checked + .checkbox-view 
    background: var(--main)
    border-color: var(--main)
    svg
      opacity: 1