
.submissions-filters
  display: flex
  align-items: flex-end
  margin-bottom: 20px
  .select-input
    min-width: 240px
    margin-top: 0
  & > *
    margin-top: 0
  & > * + *
    margin-left: 16px !important
  .button
    width: 100px
    text-align: center
    padding: 8px