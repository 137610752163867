.button {
  border-radius: 32px;
  padding: 14px 24px;
  border: none;
  outline: none;
  font-size: 1.2rem;
  font-weight: 300;
  cursor: pointer;
  margin-top: 18px;
  width: 100%;
  background: linear-gradient(60deg, var(--main) 0%, var(--main) 40%, var(--secondary) 60%);
  background-size: 250% 100%;
  background-position: 0% 50%;
  transition: background .25s;
  position: relative;
  color: #fff;
}

.button:hover {
  background-position: 100% 50%;
}

.button-click-effect::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: inherit;
  transition: .5s;
  box-shadow: 0 0 10px 10px var(--shadowColorDark);
  opacity: 0;
}

.button-click-effect:active::after {
  opacity: 1;
  box-shadow: 0 0 0 0 var(--shadowColorDark);
  transition: 0s;
}

.button-click-effect {
  position: relative;
}

.button[disabled] {
  background: #ccc;
}

.button.small {
  padding: 4px;
  font-size: 13px;
  max-width: 90px;
  text-align: center;
}

.button.no-margin {
  margin: 0;
}