.navigation-item {
  padding: 16px 32px;
  color: inherit;
  text-decoration: none;
  transition: .15s;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: var(--borderRadius);
  box-shadow: var(--shadow);
  cursor: pointer;
}
.navigation-item + .navigation-item {
  margin-top: 12px;
}

.navigation-item:not(.active):hover {
  color: #fff;
  background: var(--mainLighter);
}

.navigation-item svg.icon {
  transition: .15s;
  margin-right: 16px;
}

.navigation-item:not(.active):hover svg.icon {
  margin-right: 32px;
}

.navigation-item svg {
  font-size: 20px;
}

.navigation-item svg.arrow {
  color: #fff;
}

.navigation-item svg.arrow {
  position: absolute;
  top: 50%;
  right: 20%;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  transition: right .25s, color .15s;
}

.navigation-item:not(.active):hover svg.arrow {
  right: 16px;
  margin-right: 0;
  opacity: 1;
  visibility: visible;
}

.navigation-item.active {
  background: var(--main);
  color: #fff;
}

.navigation-item.active svg.icon {
  margin-right: 16px;
}
