
span.teams {
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 8px;
  transition: .15s;
}

span.teams:hover {
  background: var(--main);
  color: #fff;
}

.team-item {
  padding: 12px 4px;
  border-bottom: 1px solid #999;
  display: flex;
}

.team-item span {
  padding: 0 12px;
}

.team-item span:first-child {
  border-right: 1px solid #999;
  width: 200px;
}

.team-item span:last-child {
  max-width: 400px;
}

.form-create-company .select-input {
  width: 100%;
}