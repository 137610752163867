
button.view-details
  font-size: 13px
  padding: 4px
  max-width: 90px
  margin: 0

.generate-col
  display: flex
  align-items: center
  svg
    font-size: 20px
    margin-right: 8px
    &[data-generated="true"]
      color: var(--success)
    &[data-generated="false"]
      color: var(--danger)
    
  
.fit-tech-submission-modal
  display: flex
  flex-direction: column
  max-height: 80vh
  overflow-y: scroll
  padding: 24px
  margin: -32px
  width: 1150px
  ul.details, ul.scores
    list-style: none
    display: flex
    margin: -4px -16px
    padding: 0
    justify-content: space-between
    flex-wrap: wrap
    li
      width: 30%
      padding: 4px 8px
      position: relative
      margin: 16px 16px
      border-bottom: 1px solid var(--borderColor)
      span:first-child
        position: absolute
        top: -8px
        left: 0
        font-size: 11px
  ul.scores
    li.wide
      width: 10%
    li
      width: 8%
      min-width: 90px
    a.pdf
      color: var(--main)
      text-decoration: none
      font-size: 12px
      svg
        font-size: 15px
        position: relative
        top: 3px
  .toggle-collapse
    background: rgba(0,0,0, .2)
    display: flex
    align-items: center
    justify-content: center
    height: 40px
    cursor: pointer
    transition: .15s
    &:hover
      background: rgba(0,0,0, .3)
    svg
      transition: .15s
    &.collapsed
      svg
        transform: rotate(180deg)
  ul.answers
    margin: 0
    padding: 0
    display: flex
    flex-direction: column
    list-style: none
    &.collapsed
      max-height: 0
      overflow: hidden
    li
      border-bottom: 1px solid var(--borderColor)
      padding: 12px 8px
      p
        margin: 0
      .answer
        text-transform: capitalize
        padding: 0 12px
        svg
          position: relative
          top: 4px
          font-size: 18px
          margin-right: 4px
        &.positive svg
          fill: var(--success)
        &.negative svg
          fill: var(--danger)
      .question
        .variants
          margin-left: 16px

