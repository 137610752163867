.labeled-text-input label {
  display: block;
  font-weight: 300;
  transition: .15s;
  pointer-events: none;
  color: var(--textColorLight);
  position: absolute;
  top: 18px;
  left: 16px;
}

.labeled-text-input input {
  outline: none;
  padding: 18px 16px 12px;
  font-size: 1rem;
  border: none;
  background-color: transparent;
  font-weight: 300;
  border-bottom: 1px solid var(--textColorLight);
  transition: .15s;
  width: 100%;
}

.labeled-text-input input:-webkit-autofill,
.labeled-text-input input:-webkit-autofill:hover, 
.labeled-text-input input:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px var(--backgroundColor) inset !important;
  -webkit-text-fill-color: var(--textColor);
  transition: background-color 5000s ease-in-out 0s;
}

.labeled-text-input label.focused {
  font-size: .75rem;
  top: -4px;
  left: 0;
  color: var(--textColor);
}

.labeled-text-input label.error {
  color: var(--danger);
}

.labeled-text-input input:focus {
  border-color: var(--textColor);
}

.labeled-text-input input.error {
  border-color: var(--danger);
}

.labeled-text-input p.error {
  position: absolute;
  top: -4px;
  right: 0;
  font-size: .75rem;
  font-weight: 500;
  margin: 0;
  color: var(--danger);
}


.labeled-text-input {
  width: 100%;
  margin-bottom: 18px;
  position: relative;
}

.labeled-text-input:hover input:not(.error) {
  color: var(--textColor);
  border-color: var(--textColor);
}
.labeled-text-input:hover label:not(.error) {
  color: var(--textColor);
}