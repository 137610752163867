html {
  --backgroundColor: #37373d;
  --overlayBackgroundColor: rgba(55, 55, 61, .9);
  --main: #FC6535;
  --mainLight: #f7744c;
  --mainLighter: #fa825e;
  --mainTransparent: #fc63357d;
  --secondary: #8250FF;
  --tertiary: #46F274;
  --borderColor: #8f968c;
  --textColor: #ffffff;
  --textColorDark: #585858;
  --textColorLight: #bdbdbd;
  --shadowColor: #24242e;
  --shadowColorDark: #1f1f25;
  --shadow: 0 0 15px var(--shadowColor);
  --borderRadius: 16px;
  --success: #1BBF47;
  --info: #22A5F2;
  --danger: #F22E34;
  --gray200: #d6d6d6;
  --gray50: #ffffff;
  --gray400: #adadad;
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--backgroundColor);
  color: var(--textColor);
  transition: background-color .15s;
  position: relative;
  height: 100%;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input, button {
  font-family: inherit;
  color: inherit;
}

.toast-container .toast {
  background-color: var(--info);
  color: #fff;
}

.toast-container .toast svg {
  font-size: 22px;
}

.toast-container .toast.error {
  background-color: var(--danger);
}

.toast-container .toast.success {
  background-color: var(--success);
}