

.error-box {
  padding: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-box svg.main-icon {
  font-size: 96px;
}

.error-box > p {
  font-size: 24px;
  font-weight: 300;
}

.error-box button {
  text-align: center;
}