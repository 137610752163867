

.assessment-filters
  display: flex
  align-items: flex-end
  margin-bottom: 20px
  .select-input
    min-width: 240px
    margin-top: 0
  & > *
    margin-top: 0
  & > * + *
    margin-left: 16px !important
  .button
    width: 100px
    text-align: center
    padding: 8px

table.small-list
  font-size: .9em
  td, th
    padding: 12px 12px
    .role
      display: block
      max-width: 200px
    