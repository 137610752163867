.toggle {
  height: 24px;
  width: 48px;
  border-radius: 16px;
  position: relative;
  background-color: var(--textColorDark);
  cursor: pointer;
}

.toggle::before {
  position: absolute;
  content: '';
  width: 16px;
  height: 16px;
  top: 4px;
  right: 4px;
  border-radius: 8px;
  transition: .15s;
  background-color: var(--main);
}

.toggle.enabled::before {
  animation: enable-toggle .2s forwards;
  animation-iteration-count: 1;
}

.toggle.disabled::before {
  animation: disable-toggle .2s backwards;
  animation-iteration-count: 1;
}

@keyframes enable-toggle {
  0% {
    width: 16px;
    right: 4px;
  }
  40% {
    width: 40px;
    right: 4px;
  }
  70% {
    width: 40px;
    right: 4px;
  }
  100% {
    width: 16px;
    right: 28px;
  }
}

@keyframes disable-toggle {
  0% {
    width: 16px;
    right: 28px;
  }
  40% {
    width: 40px;
    right: 4px;
  }
  70% {
    width: 40px;
    right: 4px;
  }
  100% {
    width: 16px;
    right: 4px;
  }
}