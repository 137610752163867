

.loading-indicator {
  width: 120px;
  height: 96px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.loading-indicator.absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-indicator .dash {
  width: 16px;
  height: 32px;
  background: #123;
  animation-name: loading-indicator-dash;
  animation-duration: .6s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.loading-indicator .dash:nth-child(3n+1) {
  background-color: var(--main);
}
.loading-indicator .dash:nth-child(3n+2) {
  background-color: var(--secondary);
}
.loading-indicator .dash:nth-child(3n+3) {
  background-color: var(--tertiary);
}

.loading-indicator .dash:nth-child(1) {
  animation-delay: .1s;
}
.loading-indicator .dash:nth-child(2) {
  animation-delay: .2s;
}
.loading-indicator .dash:nth-child(3) {
  animation-delay: .3s;
}
.loading-indicator .dash:nth-child(4) {
  animation-delay: .4s;
}
.loading-indicator .dash:nth-child(5) {
  animation-delay: .5s;
}
.loading-indicator .dash:nth-child(6) {
  animation-delay: .6s;
}
.loading-indicator .dash:nth-child(7) {
  animation-delay: .7s;
}
.loading-indicator .dash:nth-child(8) {
  animation-delay: .8s;
}
.loading-indicator .dash:nth-child(9) {
  animation-delay: .9s;
}
.loading-indicator .dash:nth-child(10) {
  animation-delay: 1s;
}

@keyframes loading-indicator-dash {
  0% {
    transform: scaleY(1)
  }
  100% {
    transform: scaleY(3)
  }
}

.loading-indicator-box {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}